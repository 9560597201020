<template>
  <button
    class="color"
    :class="{ selected: selected }"
    @click="handleColorClicked()"
    :style="style"
  ></button>
</template>

<script>
export default {
  name: 'ColorButton',
  props: {
    color: String,
    selected: Boolean,
  },
  emits: ['colorClicked'],
  computed: {
    style() {
      const backgroundStyle = `background: ${this.color};`;

      if (!this.selected) {
        return backgroundStyle;
      }

      const borderColor = this.color === 'white' ? 'black' : 'var(--color-white)';

      return `${backgroundStyle} border: 0.2rem solid ${borderColor}; box-shadow: var(--white-shadow);`;
    },
  },
  methods: {
    handleColorClicked() {
      this.$emit('colorClicked', this.color);
    },
  },
};
</script>

<style lang="scss" scoped>
button.color {
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  border: none;
  outline: none;
  border-radius: 50%;
  transition: box-shadow 400ms ease, transform 400ms ease;

  &:hover,
  &:focus {
    transform: scale(1.1);
  }
}
</style>
