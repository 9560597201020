<template>
  <div class="illustration-canvas-container">
    <canvas
      ref="canvas"
      :width="canvasWidth"
      :height="canvasHeight"
      @mousedown="handleMouseDown($event)"
      @mouseup="handleDrawingEnd()"
      @mouseout="handleMouseOut()"
      @mousemove="draw($event)"
      @touchmove="draw($event)"
      @touchstart="handleMouseDown($event)"
      @touchend="handleDrawingEnd()"
      @touchcancel="handleMouseOut()"
    ></canvas>
    <div class="toolbar">
      <IconButton
        @clicked="openClearModal()"
        title="Clear your drawing"
        iconType="times-solid"
      ></IconButton>
      <IconButton
        @clicked="undo()"
        :disabled="cannotUndo"
        title="Undo your last change"
        iconType="undo-solid"
      ></IconButton>
      <ColorButton
        v-for="color in colors"
        :key="color"
        :color="color"
        @colorClicked="setColor($event)"
        :selected="isSelectedColor(color)"
      />
      <Modal
        :open="cancelModalOpen"
        title="Aww"
        okButtonLabel="Yes"
        @okButtonClicked="clear()"
        @cancelButtonClicked="closeClearModal()"
      >Are you sure you want to clear your art?</Modal>
    </div>
  </div>
</template>

<script>
import IconButton from './IconButton.vue';
import ColorButton from './ColorButton.vue';
import Modal from './Modal.vue';

const CANVAS_LARGE_SIZE = 400;
const CANVAS_SMALL_SIZE = 290;

export default {
  name: 'IllustrationCanvas',
  components: {
    IconButton,
    ColorButton,
    Modal,
  },
  data() {
    return {
      canvasContext: null,
      undoCanvasContext: null,
      isDrawing: false,
      lastDrawX: 0,
      lastDrawY: 0,
      canvasLeft: 0,
      canvasTop: 0,
      canvasWidth: CANVAS_LARGE_SIZE,
      canvasHeight: CANVAS_LARGE_SIZE,
      undoFrames: [],
      undoMax: 10,
      selectedColor: 'black',
      colors: [
        'black',
        'white',
        'brown',
        'red',
        'orange',
        'yellow',
        'green',
        'blue',
        'indigo',
        'violet',
      ],
      mediaQueryList: null,
      mediaQueryListener: null,
      cancelModalOpen: false,
    };
  },
  emits: ['draw', 'clear'],
  computed: {
    cannotUndo() {
      return this.undoFrames.length === 0;
    },
  },
  mounted() {
    this.canvasContext = this.$refs.canvas.getContext('2d');
    this.canvasContext.lineJoin = 'round';
    this.canvasContext.lineCap = 'round';
    this.canvasContext.lineWidth = 3;
    this.setColor('black');

    this.mediaQueryList = window.matchMedia('(max-width: 550px)');
    this.mediaQueryListener = this.setCanvasSize.bind(this);
    this.mediaQueryList.addListener(this.mediaQueryListener);
    this.setCanvasSize(this.mediaQueryList);
  },
  unmounted() {
    this.mediaQueryList.removeListener(this.mediaQueryListener);
  },
  methods: {
    setCanvasSize(event) {
      if (event.matches) {
        this.canvasWidth = CANVAS_SMALL_SIZE;
        this.canvasHeight = CANVAS_SMALL_SIZE;
      } else {
        this.canvasWidth = CANVAS_LARGE_SIZE;
        this.canvasHeight = CANVAS_LARGE_SIZE;
      }
    },
    setIsDrawing(isDrawing) {
      this.isDrawing = isDrawing;
    },
    getEventCoordinates(event) {
      this.canvasTop = this.$refs.canvas.getBoundingClientRect().top;
      this.canvasLeft = this.$refs.canvas.getBoundingClientRect().left;

      return event.type === 'mousemove' || event.type === 'mousedown'
        ? { x: event.offsetX, y: event.offsetY }
        : {
            x: event.touches[0].clientX - this.canvasLeft,
            y: event.touches[0].clientY - this.canvasTop,
          };
    },
    draw(event) {
      if (this.isDrawing) {
        const { x, y } = this.getEventCoordinates(event);

        this.canvasContext.beginPath();
        this.canvasContext.moveTo(this.lastDrawX, this.lastDrawY);
        this.canvasContext.lineTo(x, y);
        this.canvasContext.stroke();
        this.lastDrawX = x;
        this.lastDrawY = y;
      }
    },
    handleMouseDown(event) {
      const { x, y } = this.getEventCoordinates(event);
      this.undoFrames = [this.$refs.canvas.toDataURL(), ...this.undoFrames];

      if (this.undoFrames.length > this.undoMax) {
        this.undoFrames = this.undoFrames.slice(0, this.undoMax);
      }

      this.setIsDrawing(true);

      this.lastDrawX = x;
      this.lastDrawY = y;
    },
    undo() {
      const lastUndoFrame = this.undoFrames[0];
      const lastUndoImage = new Image();

      lastUndoImage.onload = () => {
        this.canvasContext.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
        this.canvasContext.drawImage(lastUndoImage, 0, 0);
        this.undoFrames = this.undoFrames.slice(1);

        if (this.undoFrames.length === 0) {
          this.$emit('clear');
        } else {
          this.$emit('draw', this.$refs.canvas.toDataURL());
        }
      };
      lastUndoImage.src = lastUndoFrame;
    },
    clear() {
      this.cancelModalOpen = false;
      this.canvasContext.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      this.undoFrames = [];
      this.$emit('clear');
    },
    setColor(color) {
      this.canvasContext.strokeStyle = color;
      this.selectedColor = color;
    },
    isSelectedColor(color) {
      return this.selectedColor === color;
    },
    handleDrawingEnd() {
      this.isDrawing = false;
      this.$emit('draw', this.$refs.canvas.toDataURL());
    },
    handleMouseOut() {
      if (this.isDrawing) {
        this.handleDrawingEnd();
      }
    },
    openClearModal() {
      this.cancelModalOpen = true;
    },
    closeClearModal() {
      this.cancelModalOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.illustration-canvas-container {
  display: flex;
  flex-direction: row;
  canvas {
    background: var(--color-white);

    &.undo {
      display: none;
    }
  }
  .toolbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90%;
    padding: 0 1rem;

    button {
      height: 2rem;
      padding: 0;
    }
  }
}

@media (max-width: 550px) {
  .illustration-canvas-container {
    flex-direction: column;

    canvas {
      order: 2;
    }

    .toolbar {
      flex-direction: row;
      margin-bottom: 1rem;
      order: 1;
      padding: 0;
    }
  }
}
</style>
