<template>
  <div class="cloud-box" ref="cloudBox">
    <img
      v-for="n in circlesNeededForTop"
      :key="n"
      src="../assets/white-circle.svg"
      class="cloud-circle"
      aria-hidden="true"
      :ref="setTopCircleRef"
      :style="getTopCircleStyle(n)"
    />

    <img
      v-for="n in circlesNeededForBottom"
      :key="n"
      src="../assets/white-circle.svg"
      class="cloud-circle"
      aria-hidden="true"
      :ref="setBottomCircleRef"
      :style="getBottomCircleStyle(n)"
    />

    <img
      v-for="n in circlesNeededForLeft"
      :key="n"
      src="../assets/white-circle.svg"
      class="cloud-circle"
      aria-hidden="true"
      :ref="setLeftCircleRef"
      :style="getLeftCircleStyle(n)"
    />

    <img
      v-for="n in circlesNeededForRight"
      :key="n"
      src="../assets/white-circle.svg"
      class="cloud-circle"
      aria-hidden="true"
      :ref="setRightCircleRef"
      :style="getRightCircleStyle(n)"
    />
    <slot></slot>
  </div>
</template>

<script>
import { nextTick } from 'vue';

export default {
  name: 'MysticalCloudBox',
  data() {
    return {
      travelLimitPx: 10,
      circleWidthPx: 50,
      circleOffsetTop: null,
      circleOffsetRight: null,
      circleOffsetBottom: null,
      circleOffsetLeft: null,
      circlesNeededForTop: 0,
      circlesNeededForBottom: 0,
      circlesNeededForLeft: 0,
      circlesNeededForRight: 0,
      circleRefsTop: [],
      circleRefsBottom: [],
      circleRefsRight: [],
      circleRefsLeft: [],
      circleVelocitiesX: [],
      circleVelocitiesY: [],
      circleTravelsX: [],
      circleTravelsY: [],
      requestAnimationFrameId: null,
      resizeHandler: null,
    };
  },
  computed: {
    circleOffsetPx() {
      return this.circleWidthPx / 2;
    },
    // This is essentially an alias which makes the code below a little easier to follow
    cloudBoxMargin() {
      return this.circleOffsetPx;
    },
  },
  methods: {
    // TODO: is there a way around having 4 identical setters here, or is that just what Vue 3 wants now?
    // I don't think we can just supply the array as an argument
    setTopCircleRef(el) {
      if (el) {
        this.circleRefsTop.push(el);
      }
    },
    setBottomCircleRef(el) {
      if (el) {
        this.circleRefsBottom.push(el);
      }
    },
    setLeftCircleRef(el) {
      if (el) {
        this.circleRefsLeft.push(el);
      }
    },
    setRightCircleRef(el) {
      if (el) {
        this.circleRefsRight.push(el);
      }
    },
    getNumberOfCirclesNeededToFillLength(regionSizePx) {
      return Math.max(Math.floor(regionSizePx / this.circleOffsetPx), 1);
    },
    getCloudBoxHeight() {
      return this.$refs.cloudBox.offsetHeight;
    },
    getCloudBoxWidth() {
      return this.$refs.cloudBox.offsetWidth;
    },
    getCirclesNeededForWidth() {
      return this.getNumberOfCirclesNeededToFillLength(this.getCloudBoxWidth());
    },
    getCirclesNeededForHeight() {
      return this.getNumberOfCirclesNeededToFillLength(this.getCloudBoxHeight());
    },
    getCirclesNeeded() {
      // We need circles to cover the width and height twice: once for the top, and once for the bottom
      return this.getCirclesNeededForWidth() * 2 + this.getCirclesNeededForHeight() * 2;
    },
    getOffsetStyleString(left, top) {
      return `left: ${left}px; top: ${top}px; width: ${this.circleWidthPx}px`;
    },
    getTopCircleStyle(n) {
      const left = n * this.circleOffsetPx - this.cloudBoxMargin;
      return this.getOffsetStyleString(left, -this.cloudBoxMargin);
    },
    getBottomCircleStyle(n) {
      const left = n * this.circleOffsetPx - this.cloudBoxMargin;
      return this.getOffsetStyleString(left, this.getCloudBoxHeight() - this.cloudBoxMargin);
    },
    getLeftCircleStyle(n) {
      const top = n * this.circleOffsetPx - this.cloudBoxMargin;
      return this.getOffsetStyleString(-this.cloudBoxMargin, top);
    },
    getRightCircleStyle(n) {
      const top = n * this.circleOffsetPx - this.cloudBoxMargin;
      return this.getOffsetStyleString(this.getCloudBoxWidth() - this.cloudBoxMargin, top);
    },
    getAllCircleRefs() {
      return [
        ...this.circleRefsTop,
        ...this.circleRefsBottom,
        ...this.circleRefsLeft,
        ...this.circleRefsRight,
      ];
    },
    initiateCircleAnimations() {
      this.circleTravelsX = [];
      this.circleTravelsY = [];
      this.circleVelocitiesX = [];
      this.circleVelocitiesY = [];

      for (let i = 0; i < this.getAllCircleRefs().length; i++) {
        this.circleVelocitiesX[i] = this.getRandomVelocity();
        this.circleVelocitiesY[i] = this.getRandomVelocity();
        this.circleTravelsX[i] = 0;
        this.circleTravelsY[i] = 0;
      }

      this.animateCircles();
    },
    animateCircles() {
      const allCircleRefs = this.getAllCircleRefs();

      for (let i = 0; i < allCircleRefs.length; i++) {
        this.animateCircle(
          i,
          allCircleRefs[i],
          this.circleVelocitiesX[i],
          this.circleVelocitiesY[i],
          this.circleTravelsX[i],
          this.circleTravelsY[i]
        );
      }

      this.requestAnimationFrameId = requestAnimationFrame(() => {
        this.animateCircles();
      });
    },
    animateCircle(index, circleRef, velocityX, velocityY, travelX, travelY) {
      const {
        updatedTravel: updatedTravelX,
        updatedVelocity: updatedVelocityX,
      } = this.getUpdatedTravel(travelX, velocityX);

      const {
        updatedTravel: updatedTravelY,
        updatedVelocity: updatedVelocityY,
      } = this.getUpdatedTravel(travelY, velocityY);

      this.circleVelocitiesX[index] = updatedVelocityX;
      this.circleVelocitiesY[index] = updatedVelocityY;
      this.circleTravelsX[index] = updatedTravelX;
      this.circleTravelsY[index] = updatedTravelY;

      circleRef.style.transform = `translateX(${updatedTravelX}px) translateY(${updatedTravelY}px)`;
    },
    // TODO: refactor/explain
    getUpdatedTravel(travel, velocity) {
      let updatedTravel = travel + velocity;
      let updatedVelocity = velocity;

      if (travel > this.travelLimitPx) {
        const difference = travel - this.travelLimitPx;
        updatedVelocity = -velocity;
        updatedTravel = this.travelLimitPx - difference;
      }

      if (travel < -this.travelLimitPx) {
        const difference = Math.abs(travel + this.travelLimitPx);
        updatedVelocity = -velocity;
        updatedTravel = -this.travelLimitPx + difference;
      }

      return { updatedTravel, updatedVelocity };
    },
    getRandomVelocity() {
      return Math.random() * 1 - 0.5;
    },
    clearAnimationFrame() {
      window.cancelAnimationFrame(this.requestAnimationFrameId);
    },
    calculateCirclesNeededAndAnimate() {
      this.clearAnimationFrame();

      this.circlesNeededForTop = this.getCirclesNeededForWidth();
      this.circlesNeededForBottom = this.getCirclesNeededForWidth();
      this.circlesNeededForLeft = this.getCirclesNeededForHeight();
      this.circlesNeededForRight = this.getCirclesNeededForHeight();

      this.initiateCircleAnimations();
    },
  },
  mounted() {
    const resizeHandler = this.calculateCirclesNeededAndAnimate.bind(this);
    this.resizeHandler = resizeHandler;

    window.addEventListener('resize', resizeHandler);
  },
  updated() {
    nextTick(() => {
      this.calculateCirclesNeededAndAnimate();
    });
  },
  beforeUpdate() {
    this.circleRefsTop = [];
    this.circleRefsBottom = [];
    this.circleRefsLeft = [];
    this.circleRefsRight = [];
  },
  unmounted() {
    this.clearAnimationFrame();
    window.removeEventListener('resize', this.resizeHandler);
  },
};
</script>

<style lang="scss" scoped>
.cloud-box {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 3rem;
}
.cloud-circle {
  position: absolute;
}
</style>
