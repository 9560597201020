<template>
  <TabContent :loading="loading" :errorOccurred="errorOccurred">
    <section>
      <h1>{{ title }}</h1>
      <p class="attribution">By {{ formattedParticipantsList }}</p>
      <h2>The last portion of the story was:</h2>
      <p v-if="!lastImage" class="last-portion">{{ lastPortion }}</p>
      <img class="last-image" v-else :src="lastImageSrc" />
      <div class="challenge-container">
        <MysticalCloudBox class="challenge">
          <MysticalTypeWriter
            v-if="challengeText"
            class="challenge-text"
            :message="challengeText"
          />
        </MysticalCloudBox>
        <MysticalObject type="thinking" />
      </div>
      <MysticalTextArea
        v-if="!isAnImageChallenge"
        placeholder="Write something here..."
        :isBlankable="false"
        v-model="submission"
        @input="handleInput()"
        :asyncValidationFnc="validateChallengeSubmission.bind(this, submission, storyId)"
        @validationSucceeded="handleValidationSucceeded()"
        @validationFailed="handleValidationFailed()"
      />
      <div v-else class="illustration-container">
        <IllustrationCanvas @draw="handleDraw($event)" @clear="handleClear()" />
      </div>
      <div v-if="isFinalChallenge" class="final-challenge-message">
        Whoa! This is the last challenge of the story! 🎉
      </div>
      <MysticalButton
        class="submit"
        label="Submit!"
        @click="handleSubmit()"
        :disabled="!validationSucceeded && !isAnImageChallenge"
      />
      <Modal
        title="All set?"
        okButtonLabel="Yep"
        :open="showConfirmationModal"
        :showSpinner="submittingRound"
        @cancelButtonClicked="handleModalCancelClicked()"
        @okButtonClicked="handleModalOkClicked()"
      >
        <p>Does this look good?</p>
        <p v-if="!isAnImageChallenge" class="submission-confirmation-text">{{ submission }}</p>
        <img v-else :src="imageDataUrl" />
      </Modal>
    </section>
  </TabContent>
</template>

<script>
import TabContent from '../components/TabContent.vue';
import MysticalTextArea from '../components/MysticalTextArea.vue';
import MysticalButton from '../components/MysticalButton.vue';
import MysticalTypeWriter from '../components/MysticalTypeWriter.vue';
import MysticalCloudBox from '../components/MysticalCloudBox.vue';
import MysticalObject from '../components/MysticalObject.vue';
import IllustrationCanvas from '../components/IllustrationCanvas.vue';
import Modal from '../components/Modal.vue';
import {
  getStoryChallenge,
  validateChallengeSubmission,
  submitRound,
  submitImage,
  getFormattedParticipantsList
} from '../services/storiesService';
import { showError, showSuccess, clearToasts } from '../services/toastService';
import { unsavedWorkMixin } from '../mixins/unsavedWorkPrompt';

const IMAGE_CHALLENGE_ID = 11;

export default {
  name: 'Contribute',
  components: {
    TabContent,
    MysticalTextArea,
    MysticalButton,
    MysticalTypeWriter,
    MysticalCloudBox,
    MysticalObject,
    IllustrationCanvas,
    Modal,
  },
  mixins: [unsavedWorkMixin],
  data() {
    return {
      loading: true,
      errorOccurred: false,
      title: '',
      lastPortion: '',
      submission: '',
      challengeText: '',
      storyId: '',
      prefaces: [
        'Hmm...',
        'Oh, I know! Yeah,',
        'Ooh! Yep,',
        'Okay, hear me out...',
        'Alright,',
        'I know just what this needs. Okay,',
        'Trust me on this,',
        'So,',
        "I'd say ",
        'This might be weird, but',
        "Let's see...",
      ],
      postfaces: [
        '.',
        '!',
        '... trust me.',
        '. Could be cool.',
        '. Just go with it!',
        ". Yeah, that'll be good.",
        '...',
        '. Uhuh, I like that.',
        ". That's the vibe I'm getting.",
        '. Oh yeah.',
        ". That'll be sweet.",
        '... uh, yeah. Do that.',
        '! Haha, yep.',
      ],
      validateChallengeSubmission,
      validationSucceeded: null,
      showConfirmationModal: false,
      submittingRound: false,
      isAnImageChallenge: false,
      userHasDrawn: false,
      imageDataUrl: '',
      lastImage: null,
      isFinalChallenge: null,
      participants: []
    };
  },
  mounted() {
    this.loadStory(this.$route.params.storyId);
  },
  beforeRouteUpdate(to, from, next) {
    this.loadStory(to.params.storyId);
    next();
  },
  computed: {
    lastImageSrc() {
      return `data:image/png;base64,${this.lastImage}`;
    },
    formattedParticipantsList() {
      return getFormattedParticipantsList(this.participants);
    }
  },
  methods: {
    loadStory(storyId) {
      this.errorOccurred = false;
      this.storyId = storyId;

      getStoryChallenge(this.storyId)
        .then(response => {
          this.title = response.gameName;
          this.lastPortion = response.lastSubmission;
          this.challengeText = this.addPrefaceAndPostfaceToChallenge(response.challengeText);
          this.isAnImageChallenge = response.challengeId === IMAGE_CHALLENGE_ID;
          this.lastImage = response.lastImage;
          this.isFinalChallenge = response.isFinalChallenge;
          this.participants = response.participants;
        })
        .catch(error => {
          if (error.status === 403) {
            showError("Hm, we couldn't access this story. This can happen if it's not your turn right now.");
          } else {
            showError("Uh oh! We couldn't load this story.");
          }

          this.errorOccurred = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addPrefaceAndPostfaceToChallenge(challengeText) {
      return `${this.pickRandom(this.prefaces)} ${challengeText}${this.pickRandom(this.postfaces)}`;
    },
    handleInput() {
      clearToasts();
      this.validationSucceeded = null;
      this.markComponentDirty();
    },
    pickRandom(array) {
      const randomIndex = Math.floor(Math.random() * array.length);
      return array[randomIndex];
    },
    handleSubmit() {
      if (!this.isAnImageChallenge && this.submission.length === 0) {
        showError("Whoops! You haven't typed anything yet. 😛");
      } else if (this.isAnImageChallenge && !this.userHasDrawn) {
        showError("Whoops! You haven't drawn anything yet. Don't be shy! 🎨");
      } else if (!this.isAnImageChallenge && !this.validationSucceeded) {
        showError(
          "Looks like there are a few issues with your submission. Fix 'em up and we'll get it submitted. 😎"
        );
      } else {
        this.showConfirmationModal = true;
      }
    },
    getImageData(imageDataUrl) {
      // Note: when canvas.toBlob() is implemented in Safari, we can use that instead of this insanity
      const byteString = atob(imageDataUrl.slice(22));

      const arrayBuffer = new ArrayBuffer(byteString.length);

      const uint8Array = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
      }

      return new Blob([arrayBuffer], { type: 'image/png' });
    },
    handleModalOkClicked() {
      this.submittingRound = true;
      const submitFnc = this.isAnImageChallenge ? submitImage : submitRound;
      const submission = this.isAnImageChallenge
        ? this.getImageData(this.imageDataUrl)
        : this.submission.trim();

      submitFnc(submission, this.storyId)
        .then(() => {
          showSuccess('Nice -- your contribution has been added to the story!');
          this.markComponentClean();
          this.$router.push('/');
          this.closeConfirmationModal();
        })
        .catch(error => {
          console.error(error);
          showError('Oh no! There was an issue submitting your round! 😮');
        })
        .finally(() => {
          this.submittingRound = false;
        });
    },
    handleModalCancelClicked() {
      this.closeConfirmationModal();
    },
    closeConfirmationModal() {
      this.showConfirmationModal = false;
    },
    handleValidationSucceeded() {
      this.validationSucceeded = true;
    },
    handleValidationFailed() {
      this.validationSucceeded = false;
    },
    handleDraw(imageDataUrl) {
      this.userHasDrawn = true;
      this.imageDataUrl = imageDataUrl;
    },
    handleClear() {
      this.userHasDrawn = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../common/_animations';

section {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  h2 {
    margin-bottom: 0;
  }

  p.attribution {
    text-align: center;
    font-size: 1.5rem;
  }

  .last-portion {
    font-size: 2rem;
    margin-bottom: 6rem;
    background: linear-gradient(180deg, var(--color-purple-darken-1), transparent);
    min-height: 8vh;
    border-radius: 1.5rem;
    padding: 1rem;
  }

  .last-image {
    background: var(--color-white);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4rem;
    width: 200px;
    height: 200px;
  }

  .submit {
    margin-left: auto;
  }

  .final-challenge-message {
    background: var(--gradient-rainbow);
    animation: rainbow_animation 15s linear infinite;
    margin-top: 1rem;
    padding: 2rem;
    font-size: 2rem;
    border-radius: 1rem;
    text-align: center;
    font-weight: bold;
    background-size: 400% 100%;
  }

  .challenge-container {
    display: flex;
    margin-bottom: 4rem;
    align-items: center;

    .challenge {
      font-size: 4rem;
      font-family: 'Fredoka One';
      color: var(--color-graphite);
      min-width: 70%;
      min-height: 20rem;
      background: var(--color-white);
      border-radius: 1.5rem;

      .challenge-text {
        z-index: 1;
        position: relative;
        padding: 2rem;
        margin: 0;
      }
    }

    .mystical-object {
      margin-left: 3rem;
      margin-top: 0;

      .icosphere {
        width: 100%;
      }

      .face {
        top: 25%;
        width: 62%;
      }
    }
  }

  .modal {
    .submission-confirmation-text {
      background: var(--color-graphite);
      padding: 1rem;
      color: white;
      border-radius: 5px;
    }
  }

  .illustration-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5rem;
  }
}

@media (max-width: 1400px) {
  section .challenge-container .challenge {
    font-size: 3rem;
  }
}

@media (max-width: 1050px) {
  section .challenge-container {
    margin-bottom: 0;
    flex-wrap: wrap;

    .mystical-object {
      width: 16rem;
      margin-top: 2rem;
      margin-left: auto;
    }
  }
}

@media (max-width: 600px) {
  section .challenge-container .challenge {
    font-size: 2rem;
  }
}
</style>
