<template>
  <p class="mystical-typewriter" ref="content">{{ printedMessage }}</p>
</template>

<script>
const DELAY_MS = 20;
const SPECIAL_TEXT_CLASS = 'special-text';
const SPECIAL_WORD_DELIMITER = '*';

export default {
  name: 'MysticalTypeWriter',
  props: {
    message: String,
  },
  data() {
    return {
      printedMessage: '',
      currentIndex: 0,
      currentSpanBeingPrinted: null,
      animationFrameId: null,
    };
  },
  mounted() {
    this.addSpanToMessage();
    this.currentSpanBeingPrinted.innerHTML = this.message[this.currentIndex];
    this.currentIndex += 1;

    this.animateText(Date.now());
  },
  computed: {
    currentlyPrintingSpecialText() {
      return this.currentSpanBeingPrinted.classList.contains(SPECIAL_TEXT_CLASS);
    },
    notCurrentlyPrintingSpecialText() {
      return !this.currentlyPrintingSpecialText;
    },
    currentCharacterIsSpecialWordDelimiter() {
      return this.message[this.currentIndex] === SPECIAL_WORD_DELIMITER;
    },
    fullMessageHasBeenPrinted() {
      return this.currentIndex === this.message.length;
    }
  },
  methods: {
    animateText(lastRenderTime) {
      this.animationFrameId = requestAnimationFrame(() => {
        this.addCharacterAfterDelay(lastRenderTime);
      });
    },
    addCharacterAfterDelay(lastRenderTime) {
      const timeSinceLastRender = Date.now() - lastRenderTime;

      if (timeSinceLastRender >= DELAY_MS) {
        this.addCharacter();
      } else {
        this.animateText(lastRenderTime);
      }
    },
    addCharacter() {
      if (this.currentCharacterIsSpecialWordDelimiter && this.notCurrentlyPrintingSpecialText) {
        this.addSpecialSpanToMessage();

        this.currentSpanBeingPrinted.innerHTML = this.message[this.currentIndex + 1];
        this.currentIndex++;
      } else if (this.currentCharacterIsSpecialWordDelimiter && this.currentlyPrintingSpecialText) {
        this.addSpanToMessage();
        this.currentSpanBeingPrinted.innerHTML = this.message[this.currentIndex + 1];
        this.currentIndex++;
      } else {
        this.currentSpanBeingPrinted.innerHTML += this.message[this.currentIndex];
      }

      this.currentIndex += 1;

      if (!this.fullMessageHasBeenPrinted) {
        this.animateText(Date.now());
      }
    },
    addSpanToMessage() {
      const span = document.createElement('span');

      this.$refs.content.appendChild(span);
      this.currentSpanBeingPrinted = span;
    },
    addSpecialSpanToMessage() {
      this.addSpanToMessage();

      this.currentSpanBeingPrinted.classList.add(SPECIAL_TEXT_CLASS);
    },
  },
  unmounted() {
    window.cancelAnimationFrame(this.animationFrameId);
  },
};
</script>

<style lang="scss">
// This CSS can't be scoped because we are adding nodes manually, breaking Vue's CSS scoping for those nodes we added.
// Is there a better way?
.special-text {
  color: transparent;
  background: var(--gradient-rainbow);
  background-clip: text;
  animation: rainbow_animation 6s linear infinite;
  background-size: 400% 100%;
}

@keyframes rainbow_animation {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 400% 0;
  }
}
</style>
